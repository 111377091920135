import './bootstrap';
import '../css/app.css';

import {createApp, h} from 'vue';
import * as Sentry from "@sentry/vue";
import {createInertiaApp} from '@inertiajs/vue3';
import {resolvePageComponent} from 'laravel-vite-plugin/inertia-helpers';
import {ZiggyVue} from '../../vendor/tightenco/ziggy/dist/vue.m';
import {createVfm} from "vue-final-modal";
import Vue3Lottie from 'vue3-lottie'
import Vue3Signature from "vue3-signature"
import Notifications from '@kyvg/vue3-notification'

String.prototype.slugify = function (separator = "-") {
    return this
        .toString()
        .normalize('NFD')                   // split an accented letter in the base letter and the acent
        .replace(/[\u0300-\u036f]/g, '')   // remove all previously split accents
        .toLowerCase()
        .trim()
        .replace(/[^a-z0-9 ]/g, '')   // remove all chars not letters, numbers and spaces (to be replaced)
        .replace(/\s+/g, separator);
};

const appName = window.document.getElementsByTagName('title')[0]?.innerText || 'Laravel';
const vfm = createVfm()

createInertiaApp({
    title: (title) => `${title} - ${appName}`,
    resolve: (name) => resolvePageComponent(`./Pages/${name}.vue`, import.meta.glob('./Pages/**/*.vue')),
    setup({el, App, props, plugin}) {
        const app_ = createApp({render: () => h(App, props)})
            .use(plugin)
            .use(vfm)
            .use(Vue3Signature)
            .use(ZiggyVue, Ziggy)
            .use(Vue3Lottie)
            .use(Notifications);


        Sentry.init({
            app: app_,
            dsn: "https://a8a501a51429b32c0b98b6a324096e42@o4505746579718144.ingest.sentry.io/4506584546607104",
            integrations: [
                new Sentry.BrowserTracing({
                    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
                    tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
                }),
                new Sentry.Replay({
                    maskAllText: false,
                    blockAllMedia: false,
                }),
            ],
            enabled: import.meta.env.APP_ENV === 'production',
            // Performance Monitoring
            tracesSampleRate: 1.0, //  Capture 100% of the transactions
            // Session Replay
            replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
            replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
        });

        app_.config.globalProperties.$filters = {
            toCurrency(value) {
                if (typeof value !== "number") {
                    return value;
                }
                var formatter = new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: 'USD'
                });
                return formatter.format(value);
            }
        }

        app_.mount(el);
    },
    progress: {
        color: '#4B5563',
    },
});


